import React from 'react';

import {SubHeading} from '../../components';
import {images} from '../../constants';
import './Chef.css';
import Fade from 'react-reveal/Fade';

const Chef = () => (
    <div className="app__bg app__wrapper section__padding">
      <div className="app__wrapper_img app__wrapper_img-reverse">
        <img src={images.chef} alt="chef_image"/>
      </div>
      <Fade right cascade>
        <div className="app__wrapper_info">
          <SubHeading title="Chef's word"/>
          <h1 className="headtext__cormorant">What we believe in</h1>

          <div className="app__chef-content">
            <div className="app__chef-content_quote">
              <img src={images.quote} alt="quote_image"/>
              <p className="p__opensans">Chúng tôi mong muốn được phục vụ khách hàng một cách tốt nhất</p>
            </div>
            <p className="p__opensans">Hy vọng sẽ được gặp gỡ và hợp tác với khách hàng từ ẩm thực cho tới các lĩnh vực
              đầu tư khác</p>
          </div>

          <div className="app__chef-sign">
            <p>TrungNV</p>
            <p className="p__opensans">Chef & Founder</p>
            <img src={images.sign} alt="sign_image"/>
          </div>
        </div>
      </Fade>
    </div>
);

export default Chef;
