import React from 'react';

import {SubHeading} from '../../components';
import {images, data} from '../../constants';
import './Laurels.css';
import Fade from 'react-reveal/Fade';

const AwardCard = ({award: {imgUrl, title, subtitle}}) => (
    <div className="app__laurels_awards-card">
      <img src={imgUrl} alt="awards"/>
      <div className="app__laurels_awards-card_content">
        <p className="p__cormorant" style={{color: '#DCCA87'}}>{title}</p>
        <p className="p__opensans">{subtitle}</p>
      </div>
    </div>
);

const Laurels = () => (
    <div className="app__bg app__wrapper section__padding" id="awards">
      <Fade left cascade>
        <div className="app__wrapper_info">
          <SubHeading title="về chúng tôi"/>
          <h1 className="headtext__cormorant">Sự công nhận</h1>

          <div className="app__laurels_awards">
            {data.awards.map((award) => <AwardCard award={award} key={award.title}/>)}
          </div>
        </div>
      </Fade>
      <div className="app__wrapper_img">
        <img src={images.laurels} alt="laurels_img"/>
      </div>
    </div>
);

export default Laurels;
