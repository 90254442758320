import images from './images';

const wines = [
  {
    title: 'Gà hấp',
    price: '220k ±10%',
    tags: '/1 Con',
  },
  {
    title: 'Gà nướng',
    price: '220k ±10%',
    tags: '/1 Con',
  },
  {
    title: 'Gà xáo gừng',
    price: '220k ±10%',
    tags: '/1 Con',
  },
  {
    title: 'Chim quay',
    price: 'từ 70k.....',
    tags: 'Chim ngói, chim giàm, chim câu',
  },
  {
    title: 'Xôi nương',
    price: 'từ 30k.....',
    tags: '/1 đĩa',
  },
];

const cocktails = [
  {
    title: 'Bia',
    price: '',
    tags: 'Bia sài gòn/bia Hà Nội/bia Tiger/bia Heniken',
  },
  {
    title: "Rượu",
    price: '',
    tags: 'Rượu táo mèo/rượu ba kích/rượu chuối hột/rượu sim',
  },
  {
    title: 'Nước ngọt',
    price: '',
    tags: 'Redbull/Pepsi/Coca/Twitter',
  },
  {
    title: 'Nước lọc',
    price: '',
    tags: 'Lavie/Aquafina',
  },
  {
    title: 'Trà đá',
    price: '',
    tags: '.',
  },
];

const awards = [
  {
    imgUrl: images.award01,
    title: 'Vệ sinh an toàn thực phẩm',
    subtitle: '',
  },
  {
    imgUrl: images.award01,
    title: '4.1 ★ google maps',
    subtitle: '',
  }
];

export default { wines, cocktails, awards };
