import React from 'react';

import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';
import Fade from 'react-reveal/Fade';

const Newsletter = () => (
    <Fade>
      <div className="app__newsletter">
        <div className="app__newsletter-heading">
          <SubHeading title="Rattings"/>
          <h1 className="headtext__cormorant">Đánh giá chúng tôi</h1>
          <p className="p__opensans">Chúng tôi luôn đón nhận và không ngừng cải thiện dịch vụ</p>
        </div>
        <div className="app__newsletter-input flex__center">
          <button type="button" className="custom__button" onClick={() => {
            window.open("https://g.page/r/CT1E6Rsbz77eEBM/review")
          }}>Đánh giá trên google maps
          </button>
        </div>
      </div>
    </Fade>
);

export default Newsletter;
