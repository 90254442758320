import React from 'react';

import {images} from '../../constants';
import './AboutUs.css';
import Fade from 'react-reveal/Fade';

const AboutUs = () => (
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
      <div className="app__aboutus-overlay flex__center">
        <img src={images.G} alt="G_overlay"/>
      </div>

      <div className="app__aboutus-content flex__center">
        <Fade>
          <div className="app__aboutus-content_about">
            <h1 className="headtext__cormorant">Về chúng tôi</h1>
            <img src={images.spoon} alt="about_spoon" className="spoon__img"/>
            <p className="p__opensans">Nhà Hàng Gà Ri Thành Trung được biết đến với các món ăn tươi sống, thực phẩm tươi
              ngon sạch sẽ và giá cả hợp lý. Từ các món ăn dân dã quen thuộc đến các món ăn đặc sản núi rừng</p>
            {/*<button type="button" className="custom__button">Know More</button>*/}
          </div>
        </Fade>

        <Fade>
          <div className="app__aboutus-content_knife flex__center">
            <img src={images.knife} alt="about_knife"/>
          </div>
        </Fade>

        <Fade>
          <div className="app__aboutus-content_history">
            <h1 className="headtext__cormorant">Phương châm</h1>
            <img src={images.spoon} alt="about_spoon" className="spoon__img"/>
            <p className="p__opensans">với thực phẩm đầu vào được tuyển chọn kỹ lưỡng và được chế biến bởi đầu bếp hơn
              20 năm kinh nghiệm, rất hy vọng đem lại cho quý khách những bữa ăn ngon miệng và hài lòng nhất.</p>
            {/*<button type="button" className="custom__button">Know More</button>*/}
          </div>
        </Fade>
      </div>
    </div>
);

export default AboutUs;
