import React from 'react';
import {BsInstagram, BsArrowLeftShort, BsArrowRightShort} from 'react-icons/bs';

import {SubHeading} from '../../components';
import {images} from '../../constants';
import './Gallery.css';
import Fade from 'react-reveal/Fade';

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const {current} = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
      <div className="app__gallery flex__center">
        <Fade left cascade>
          <div className="app__gallery-content">
            <SubHeading title="Photo Gallery"/>
            <h1 className="headtext__cormorant">Hình ảnh</h1>
            <p className="p__opensans" style={{color: '#AAAAAA', marginTop: '2rem'}}>Hình ảnh các món ăn thực tế</p>
            {/*<button type="button" className="custom__button">View More</button>*/}
          </div>
        </Fade>
        <div className="app__gallery-images">
          <div className="app__gallery-images_container" ref={scrollRef}>
            {[images.gallery01, images.gallery02, images.gallery03, images.gallery04].map((image, index) => (
                <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
                  <img src={image} alt="gallery_image"/>
                  <BsInstagram className="gallery__image-icon"/>
                </div>
            ))}
          </div>
          <div className="app__gallery-images_arrows">
            <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')}/>
            <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')}/>
          </div>
        </div>
      </div>
  );
};

export default Gallery;
