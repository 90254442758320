import React from 'react';

import {SubHeading} from '../../components';
import {images} from '../../constants';
import Fade from 'react-reveal/Fade';

const FindUs = () => (
    <div className="app__bg app__wrapper section__padding" id="contact">
      <div className="app__wrapper_info">
        <SubHeading title="Địa chỉ"/>

        <h1 className="headtext__cormorant" style={{marginBottom: '3rem'}}>Tìm kiếm cửa hàng</h1>
        <Fade left cascade>
          <div className="app__wrapper-content">
            <p className="p__opensans">Km 13 Quốc Lộ 21, Thạch Hoà, Thạch Thất, Hà Nội, Vietnam</p>
            <p className="p__cormorant" style={{color: '#DCCA87', margin: '2rem 0'}}>Giờ mở cửa</p>
            <p className="p__opensans">Mon - Fri: 09:00 am - 11:00 pm</p>
            <p className="p__opensans">Sat - Sun: 09:00 am - 11:00 pm</p>
          </div>
        </Fade>
        <button type="button" className="custom__button" style={{marginTop: '2rem'}} onClick={() => {
          window.open("https://goo.gl/maps/p3rhT4bXNzRwRv7u6")
        }}>Visit Us
        </button>
      </div>

      <div className="app__wrapper_img">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2214.81180209829!2d105.52346617004793!3d20.99846436808053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31345ba7f11f5ce7%3A0xdebecf1b1be9443d!2sTh%C3%A0nh%20Trung%20Chicken%20Restaurant!5e0!3m2!1sen!2s!4v1671730364007!5m2!1sen!2s"
            width="600" height="450" allowFullScreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"/>
      </div>
    </div>
);

export default FindUs;
