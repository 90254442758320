import React from 'react';

import {SubHeading} from '../../components';
import {images} from '../../constants';
import './Header.css';
import Fade from 'react-reveal/Fade';

const Header = () => (
    <div className="app__header app__wrapper section__padding" id="home">
      <Fade left cascade>
        <div className="app__wrapper_info">
          <SubHeading title="Nhà hàng Thành Trung"/>
          <h1 className="app__header-h1">The Key To Fine Dining</h1>
          <p className="p__opensans" style={{margin: '2rem 0'}}>Đặc sản các món ăn dân tộc<br/>Gà Ri - Lợn mán - Chim -
            Thỏ - Xôi nương - Cơm quê</p>
          <div className="custom__button"><a href="#menu">Explore Menu</a></div>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="app__wrapper_img">
          <img src={images.welcome} alt="header_img"/>
        </div>
      </Fade>
    </div>
);

export default Header;
