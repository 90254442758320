import React from 'react';
import {FiFacebook, FiTwitter, FiInstagram} from 'react-icons/fi';

import {FooterOverlay, Newsletter} from '../../components';
import {images} from '../../constants';
import './Footer.css';

const Footer = () => (
    <div className="app__footer section__padding" id="login">
      <FooterOverlay/>
      <Newsletter/>

      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">Thông tin liên hệ</h1>
          <p className="p__opensans">Km 13 Quốc Lộ 21, Thạch Hoà, Thạch Thất, Hà Nội, Vietnam</p>
          <a className="p__opensans" href="tel:+84974255619">0974255619</a>
        </div>

        <div className="app__footer-links_logo">
          <img src={images.gericht} alt="footer_logo"/>
          <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of
            others.&quot;</p>
          <img src={images.spoon} className="spoon__img" style={{marginTop: 15}}/>
          <div className="app__footer-links_icons">
            <div onClick={() => {
              window.open("https://goo.gl/maps/bqT991xsVX6VxfQF9")
            }}>
              <FiFacebook/>
            </div>
            <div onClick={() => {
              window.open("https://goo.gl/maps/bqT991xsVX6VxfQF9")
            }}>
              <FiTwitter/>
            </div>
            <div onClick={() => {
              window.open("https://goo.gl/maps/bqT991xsVX6VxfQF9")
            }}>
              <FiInstagram/>
            </div>
          </div>
        </div>

        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">Giờ làm việc</h1>
          <p className="p__opensans">Monday-Friday:</p>
          <p className="p__opensans">09:00 am - 11:00 pm</p>
          <p className="p__opensans">Saturday-Sunday:</p>
          <p className="p__opensans">09:00 am - 11:00 pm</p>
        </div>
      </div>

      <div className="footer__copyright">
        <p className="p__opensans">2022 ThanhTrung Restaurant. All Rights reserved.</p>
      </div>

    </div>
);

export default Footer;
