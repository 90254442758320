import React from 'react';

import {AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu} from './container';
import {Navbar} from './components';
import './App.css';
import FadeIn from 'react-fade-in';

const App = () => (
    <FadeIn transitionDuration={1000}>
      <div>
        <Navbar/>
        <Header/>
        <AboutUs/>
        <SpecialMenu/>
        <Chef/>
        <Intro/>
        <Laurels/>
        <Gallery/>
        <FindUs/>
        <Footer/>
      </div>
    </FadeIn>
);

export default App;
